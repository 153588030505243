import React from "react"
import { Link, useNavigate } from "react-router-dom"
import styled from "styled-components"
import ohdearImg from "../../../assets/ohdear.png"
import winnerImg from "../../../assets/winner.png"
import { getCookies } from "../../../utils/cookies"
import { CookiesKeys } from "../../../utils/keys"
import PopupInner from "../../PopupInner"
import TermsAndConditions from "../../TermsAndConditions"
import AjaxButton from "./AjaxButton"
import lostImg from "../../../assets/lost.png"

const StyledPrizeLayout = styled.div`
  @media (max-width: 500px) {
    margin-top: -20px;
  }
  .prize__title {
    max-width: 225px;
  }
  .prize__img {
    max-width: 200px;
  }
`

interface Props {
  attemptsLeft?: boolean
  copy?: string | null
  image?: string
  button1?: string
  button2?: string
  buttonLink?: string
  lost?: boolean
  score?: number
}

export default function PrizeLayout({
  attemptsLeft,
  copy,
  image,
  button1,
  button2,
  buttonLink,
  lost,
  score,
}: Props) {
  const navigate = useNavigate()

  return (
    <>
      <StyledPrizeLayout>
        <PopupInner>
          <img
            alt={lost ? "Oh Dear" : "Winner"}
            src={lost ? ohdearImg : winnerImg}
            className="prize__title"
          />
          <p>{copy?.replace("{score}", `${score}`)}</p>
          <img alt="" src={lost ? lostImg : image} className="prize__img" />
          {!lost && <AjaxButton>{button1 || "Add to bag"}</AjaxButton>}
          {attemptsLeft ? (
            <button onClick={() => navigate(-1)} className="button">
              {button2 || "Play again"}
              <span
                style={{
                  display: "block",
                  fontSize: "0.9rem",
                  fontFamily: "Cormorant Garamond",
                  textTransform: "lowercase",
                  lineHeight: 1,
                }}
              >
                {getCookies(CookiesKeys.RingToss)} tries remaining
              </span>
            </button>
          ) : (
            <a href={buttonLink} className="button">
              {button2 || "Continue shopping"}
            </a>
          )}
          <Link to="/" className="button-alt">
            Back to the fair
          </Link>
        </PopupInner>
        <TermsAndConditions />
      </StyledPrizeLayout>
    </>
  )
}
