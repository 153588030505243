export const contentApiUrl = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/api/v1/content`
  : "/api/v1/content"

export const startApiUrl = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/api/v1/start`
  : "/api/v1/start"

export const endApiUrl = process.env.REACT_APP_API_URL
  ? `${process.env.REACT_APP_API_URL}/api/v1/end`
  : "/api/v1/end"