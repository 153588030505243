import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import backImg from "../assets/back.png"

const StyledBackButton = styled.div`
  font-size: 18px;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    img {
      height: 22px;
      margin-right: 5px;
    }
  }
`

export default function BackButton() {
  return (
    <StyledBackButton>
      <Link to="/" className="signmaster">
        <img alt="back arrow" src={backImg} /> Back
      </Link>
    </StyledBackButton>
  )
}
