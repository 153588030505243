import React from "react"

export const usePageLoad = () => {
  const [loading, setLoading] = React.useState<boolean>(true)

  function onPageLoad() {
    setTimeout(() => {
      setLoading(false)
    }, 250)
  }

  React.useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad()
    } else {
      window.addEventListener("load", onPageLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad)
    }
  }, [])

  return loading
}
