import React from "react"
import styled from "styled-components"
import Phaser from "phaser"
import Preloader from "./phaser/Preloader"
import Practise from "./phaser/Practise"
import Game from "./phaser/Game"
import { eventsCenter } from "../../../utils/eventsEmmiter"
import { useRingTossStore } from "../../../utils/ringTossStore"
import { Navigate } from "react-router-dom"
import { getCookies, setCookiesForTomorrow } from "../../../utils/cookies"
import { usePageLoad } from "../../../utils/usePageLoad"
import Loading from "../../Loading"
import { CookiesKeys } from "../../../utils/keys"
import PractiseMessage from "./PractiseMessage"
import axios from "axios"
import { startApiUrl } from "../../../utils/apiUrls"
import { useGlobalStore } from "../../../utils/globalStore"

const StyledRingTossPoints = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  .ringtoss__points__container {
    text-align: center;
    .ringtoss__points__label {
      font-size: 0.9rem;
    }
    .ringtoss__points {
      font-size: 2rem;
      line-height: 0.75;
    }
  }
`

export default function RingTossPlay() {
  const [attempts, setAttempts] = React.useState<number>(10)
  const [gameOver, setGameOver] = React.useState<boolean>(false)
  const [isPractise, setIsPractise] = React.useState<string>("")
  const [loading, setLoading] = React.useState<boolean>(true)

  const locale = useGlobalStore((state) => state.locale)
  const setId = useRingTossStore((state) => state.setId)
  const score = useRingTossStore((state) => state.score)
  const setScore = useRingTossStore((state) => state.setScore)
  const tries = useRingTossStore((state) => state.tries)
  const isLoading = usePageLoad()

  async function handleApiStart() {
    try {
      const res = await axios.post(startApiUrl, {
        game: process.env.REACT_APP_RINGTOSS_QUERY,
        country: locale,
        try: getCookies(CookiesKeys.RingToss),
      })
      setId(res?.data.data.id)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: 375,
      height: 450,
      parent: "game__wrapper",
      canvas: document.getElementById("game__canvas") as HTMLCanvasElement,
      transparent: true,
      physics: {
        default: "arcade",
        arcade: {
          debug: process.env.NODE_ENV === "development",
        },
      },
      scene: [Preloader, Practise, Game],
    }

    if (!isLoading) {
      handleApiStart()
      handleUpdateScore(0)
      new Phaser.Game(config)
      setCookiesForTomorrow(CookiesKeys.RingToss, tries - 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  function handleUpdateAttempts(s: number | string) {
    setAttempts(parseInt(`${s}`, 10) + 1)
  }

  function handleUpdateScore(s: number | string) {
    setScore(parseInt(`${s}`, 10))
  }

  function handleGameOver() {
    setGameOver(true)
  }

  function handleSetIsPractise(p: string) {
    setIsPractise(p)
  }

  React.useEffect(() => {
    eventsCenter.on("attempts", handleUpdateAttempts)
    eventsCenter.on("score", handleUpdateScore)
    eventsCenter.once("gameover", handleGameOver)
    eventsCenter.on("practise", handleSetIsPractise)

    return () => {
      eventsCenter.off("attempts", handleUpdateAttempts)
      eventsCenter.off("score", handleUpdateScore)
      eventsCenter.off("gameover", handleGameOver)
      eventsCenter.off("practise", handleSetIsPractise)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {getCookies(CookiesKeys.RingToss) < 0 && (
        <Navigate to="/ring-toss" replace={true} />
      )}
      {gameOver && <Navigate to="/ring-toss/prize" />}
      <StyledRingTossPoints>
        <div className="ringtoss__points__container">
          <div className="ringtoss__points__label">POINTS</div>
          <div className="ringtoss__points signmaster">{score}</div>
        </div>
        <div className="ringtoss__points__container">
          <div className="ringtoss__points__label">RINGS</div>
          <div className="ringtoss__points signmaster">
            {attempts >= 0 ? attempts : 0}
          </div>
        </div>
      </StyledRingTossPoints>
      <div id="game__wrapper">{/* canvas goes here */}</div>
      {isPractise && <PractiseMessage isPractise={isPractise} />}
      {loading && <Loading />}
      {isLoading && <Loading />}
    </>
  )
}
