import React from "react"
import styled from "styled-components"

const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: var(--maroon);
  z-index: 9999999;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Loading() {
  return (
    <StyledLoading>
      <div className="loading__inner">
        <h3>Loading...</h3>
      </div>
    </StyledLoading>
  )
}
