import React from "react"
import PopupInner from "../../PopupInner"
import TermsAndConditions from "../../TermsAndConditions"
import ringtossHomeImg from "../../../assets/ringtoss-home.png"
import { Link } from "react-router-dom"

interface Props {
  intro?: string
  button?: string
  noTries?: boolean
  setShowInstructions: React.Dispatch<React.SetStateAction<boolean>>
}

export default function HomeLayout({
  intro,
  button,
  noTries,
  setShowInstructions,
}: Props) {
  function handleButton() {
    setShowInstructions(true)
  }

  return (
    <div>
      <div style={{ height: 40 }} />
      <PopupInner>
        <img
          alt=""
          src={ringtossHomeImg}
          style={{ marginTop: -100, zIndex: 3 }}
        />
        <p>{intro}</p>
        {noTries ? (
          <Link to="/" className="button-alt">
            {button}
          </Link>
        ) : (
          <button onClick={handleButton} className="button">
            {button}
          </button>
        )}
      </PopupInner>
      <TermsAndConditions />
    </div>
  )
}
