import React from "react"
import styled from "styled-components"
import AudioButton from "./AudioButton"
import BackButton from "./BackButton"

const StyledTopButtonFlex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  @media (min-width: 500px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
`

export default function TopButtonFlex() {
  return (
    <StyledTopButtonFlex>
      <BackButton />
      <AudioButton />
    </StyledTopButtonFlex>
  )
}
