import React from "react"
import Loading from "../../Loading"
import HomeLayout from "./HomeLayout"
import Instructions from "./Instructions"
import { usePageLoad } from "../../../utils/usePageLoad"
import { useLocaleCheck } from "../../../utils/useLocaleCheck"
import { useGlobalStore } from "../../../utils/globalStore"
import { getCookies } from "../../../utils/cookies"
import { CookiesKeys } from "../../../utils/keys"

export default function RingTossHome() {
  const [showInstructions, setShowInstructions] = React.useState<boolean>(false)
  const data = useGlobalStore((state) => state.data)
  const homeData = useLocaleCheck(data?.home)
  const instructionData = useLocaleCheck(data?.instructions)
  const noTriesData = useLocaleCheck(data?.["no-tries"])
  const noTries = getCookies(CookiesKeys.RingToss) <= 0

  return (
    <>
      {showInstructions ? (
        <Instructions data={instructionData} />
      ) : noTries ? (
        <>
          <HomeLayout
            intro={noTriesData?.copy}
            button={noTriesData?.button_label}
            noTries={noTries}
            setShowInstructions={setShowInstructions}
          />
        </>
      ) : (
        <HomeLayout
          intro={homeData?.intro}
          button={homeData?.button_text}
          noTries={noTries}
          setShowInstructions={setShowInstructions}
        />
      )}
      {usePageLoad() && <Loading />}
    </>
  )
}
