import React from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import fortuneCaseImg from "../../assets/fortune-case.png"
import fortuneHeadImg from "../../assets/fortune-teller-head.png"
import fortuneJawImg from "../../assets/fortune-teller-jaw.png"
import { usePageLoad } from "../../utils/usePageLoad"
import Loading from "../Loading"

const StyledFortune = styled.div`
  transform-origin: top center;
  @media (max-width: 400px) {
    transform: scale(0.9);
  }
  .fortune__case__container {
    position: relative;
    width: 350px;
    .fortune__head__container {
      position: absolute;
      top: 150px;
      left: 50%;
      width: 200px;
      transform: translate(-50%, 0);
      .fortune__jaw {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .fortune__message__container {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-10deg);
      .fortune__message {
        background: var(--white);
        color: var(--black);
        width: 380px;
        padding: 10px 80px;
        box-shadow: 5px 5px 15px black;
        span {
          font-size: 4rem;
          color: var(--red);
          line-height: 1;
        }
      }
    }
  }
`

interface Props {
  data?: any
}

export default function FortuneHome({ data }: Props) {
  const [xmasDate, setXmasDate] = React.useState<number>(0)
  
  React.useEffect(() => {
    gsap.to(".fortune__jaw", { y: 20, duration: 1, repeat: -1, yoyo: true })

    gsap.fromTo(
      ".fortune__message",
      { scale: 0, y: -150, opacity: 0, rotate: 45 },
      { scale: 1, y: 0, opacity: 1, rotate: 0, delay: 2, duration: 1 }
    )
  }, [])

  React.useEffect(() => {
    const startDate = Date.now()
    const endDate = parseInt(`${data?.fortune_teller_date}000`, 10)

    const diffInMs = endDate - startDate
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

    // console.log(Math.floor(diffInDays - 1))
    setXmasDate(Math.floor(diffInDays))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <StyledFortune>
        <div className="fortune__case__container">
          <img alt="" src={fortuneCaseImg} className="fortune__case" />
          <div className="fortune__head__container">
            <img alt="" src={fortuneHeadImg} className="fortune__head" />
            <img alt="" src={fortuneJawImg} className="fortune__jaw" />
          </div>
          <div className="fortune__message__container">
            <div className="fortune__message">
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.fortune_teller_text.replace(
                    "{days}",
                    `</br><span class="signmaster">${xmasDate}</span></br>`
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </StyledFortune>
      {usePageLoad() && <Loading />}
    </>
  )
}
