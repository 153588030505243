import Phaser from "phaser"
import { AnimationKeys, AudioKeys, SceneKeys, TextureKeys } from "./keys"
import { eventsCenter } from "../../../../utils/eventsEmmiter"

export default class Preloader extends Phaser.Scene {
  private audioOn: boolean =
    localStorage.getItem("audio") === "off" ? false : true

  constructor() {
    super(SceneKeys.Preloader)
  }

  preload() {
    this.load.image(TextureKeys.Shelf, "/ring-toss/shelf.png")
    this.load.image(TextureKeys.Ring, "/ring-toss/ring.png")
    this.load.atlas(
      TextureKeys.WoodPole,
      "/ring-toss/sprites/wood.png",
      "/ring-toss/sprites/wood.json"
    )
    this.load.atlas(
      TextureKeys.GoldPole,
      "/ring-toss/sprites/gold.png",
      "/ring-toss/sprites/gold.json"
    )
    this.load.atlas(
      TextureKeys.CandyPole,
      "/ring-toss/sprites/candy.png",
      "/ring-toss/sprites/candy.json"
    )
    this.load.audio(AudioKeys.Ambience, "/ambience.mp3")
    this.load.audio(AudioKeys.Cheer, "/cheers.mp3")
  }

  create() {
    const isAudioOn = localStorage.getItem("audio") === "off" ? false : true
    this.audioOn = isAudioOn

    eventsCenter.on("audio", (a: any) => {
      this.audioOn = a
      this.sound.mute = !this.audioOn
    })

    this.anims.create({
      key: AnimationKeys.WoodPole,
      frames: this.anims.generateFrameNames(TextureKeys.WoodPole, {
        start: 3,
        end: 9,
        prefix: "wood-",
        suffix: ".png",
      }),
      frameRate: 12,
      delay: 175,
    })
    this.anims.create({
      key: AnimationKeys.GoldPole,
      frames: this.anims.generateFrameNames(TextureKeys.GoldPole, {
        start: 3,
        end: 9,
        prefix: "gold-",
        suffix: ".png",
      }),
      frameRate: 12,
      delay: 175,
    })
    this.anims.create({
      key: AnimationKeys.CandyPole,
      frames: this.anims.generateFrameNames(TextureKeys.CandyPole, {
        start: 3,
        end: 9,
        prefix: "candy-",
        suffix: ".png",
      }),
      frameRate: 12,
      delay: 175,
    })
    const amb = this.sound.add(AudioKeys.Ambience)
    amb.play({ volume: 0.2, loop: true })
  }

  update() {
    if (sessionStorage.getItem("played")) {
      this.scene.start(SceneKeys.Game)
    } else {
      this.scene.start(SceneKeys.Practise)
    }
  }
}
