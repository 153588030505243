import React from "react"
import { createPortal } from "react-dom"

interface Props {
  where: string
  children: React.ReactNode
}

export default function Portal({ where, children }: Props) {
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted
    ? createPortal(
        children,
        document.getElementById(`portal-${where}`) as HTMLElement
      )
    : null
}
