import React from "react"
import axios from "axios"
import { Route, Routes, useSearchParams } from "react-router-dom"
import "./styles/reset.css"
import "./styles/fonts.css"
import { createGlobalStyle } from "styled-components"
import global from "./styles/global"
import Home from "./components/home/Home"
import FortuneHome from "./components/fortune/FortuneHome"
import RingTossHome from "./components/ring-toss/home/RingTossHome"
import RingTossPlay from "./components/ring-toss/play/RingTossPlay"
import RingTossPrize from "./components/ring-toss/prize/RingTossPrize"
import StrengthTestHome from "./components/strength-test/StrengthTestHome"
import StrengthTestPlay from "./components/strength-test/StrengthTestPlay"
import StrengthTestPrize from "./components/strength-test/StrengthTestPrize"
import { useCalculateVh } from "./utils/useCalculateVh"
import OutletLayout from "./components/OutletLayout"
import Loading from "./components/Loading"
import { useGlobalStore } from "./utils/globalStore"
import { useRingTossStore } from "./utils/ringTossStore"
import { contentApiUrl } from "./utils/apiUrls"
import { useLocaleCheck, useSettingsCheck } from "./utils/useLocaleCheck"

const GlobalStyles = createGlobalStyle`
${global}
`

export default function App() {
  const [loading, setLoading] = React.useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const data = useGlobalStore((state) => state.data)
  const setData = useGlobalStore((state) => state.setData)
  const setStrengthData = useGlobalStore((state) => state.setStrengthData)
  const setLang = useGlobalStore((state) => state.setLang)
  const setLocale = useGlobalStore((state) => state.setLocale)
  const setRingTossTries = useRingTossStore((state) => state.setTries)
  const homeData = useLocaleCheck(data?.["landing-page"])
  const settingData = useSettingsCheck(data?.settings)
  useCalculateVh()

  function handleLocaleUpdate() {
    if (localStorage.getItem("locale")) {
      const l = localStorage.getItem("locale")
      setLocale(l || "uk")
    } else if (searchParams.get("locale") === "us") {
      setLocale("us")
      localStorage.setItem("locale", "us")
    } else {
      setLocale("uk")
      localStorage.setItem("locale", "uk")
    }
  }

  function handleLangUpdate() {
    if (localStorage.getItem("lang")) {
      const l = localStorage.getItem("lang")
      setLang(l || "en")
    } else if (searchParams.get("lang") === "de") {
      setLang("de")
      localStorage.setItem("lang", "de")
    } else if (searchParams.get("lang") === "es") {
      setLang("es")
      localStorage.setItem("lang", "es")
    } else if (searchParams.get("lang") === "fr") {
      setLang("fr")
      localStorage.setItem("lang", "fr")
    } else if (searchParams.get("lang") === "it") {
      setLang("it")
      localStorage.setItem("lang", "it")
    } else {
      setLang("en")
      localStorage.setItem("lang", "en")
    }
  }

  async function getContent() {
    try {
      handleLocaleUpdate()
      handleLangUpdate()
      const res = await axios.get(
        `${contentApiUrl}?game=${process.env.REACT_APP_RINGTOSS_QUERY}`
      )
      const strengthRes = await axios.get(
        `${contentApiUrl}?game=${process.env.REACT_APP_STRENGTHTEST_QUERY}`
      )
      setData(res?.data.data)
      setStrengthData(strengthRes?.data.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setRingTossTries(settingData?.tries_per_day)
    localStorage.setItem("rt-difficulty", settingData?.difficulty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route index element={<Home data={homeData} />} />
        <Route path="fortune" element={<OutletLayout />}>
          <Route index element={<FortuneHome data={homeData} />} />
        </Route>
        <Route path="ring-toss" element={<OutletLayout />}>
          <Route index element={<RingTossHome />} />
          <Route path="play" element={<RingTossPlay />} />
          <Route path="prize" element={<RingTossPrize />} />
          <Route path="*" element={<RingTossHome />} />
        </Route>
        <Route path="strength-test" element={<OutletLayout />}>
          <Route index element={<StrengthTestHome />} />
          <Route path="play" element={<StrengthTestPlay />} />
          <Route path="prize" element={<StrengthTestPrize />} />
          <Route path="*" element={<StrengthTestHome />} />
        </Route>
        <Route path="*" element={<Home data={homeData} />} />
      </Routes>
      {loading && <Loading />}
    </>
  )
}
