import React from "react"
import styled from "styled-components"
import corner from "../assets/corner.svg"

const StyledPopupContainer = styled.div`
  position: relative;
  margin: 25px;
`

const StyledPopupInner = styled.div`
  position: relative;
  max-width: 300px;
  margin: 40px auto 0;
  padding: 5px;
  background: var(--white);
  color: var(--black);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.8));
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .popup-inner::-webkit-scrollbar {
    display: none;
  }
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popup-content > *:not(:last-child) {
    margin-bottom: 15px;
  }
  .popup-corner {
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    pointer-events: none;
    user-select: none;
    &.popup-corner-1 {
      top: -25px;
      left: -25px;
    }
    &.popup-corner-2 {
      top: -25px;
      right: -25px;
      transform: rotate(90deg);
    }
    &.popup-corner-3 {
      bottom: -25px;
      right: -25px;
      transform: rotate(180deg);
    }
    &.popup-corner-4 {
      bottom: -25px;
      left: -25px;
      transform: rotate(270deg);
    }
  }
  .popup-fake-padding {
    display: block;
    position: absolute;
    background: var(--white);
    user-select: none;
    &.popup-fake-padding-1 {
      height: 25px;
      width: 100%;
      top: -25px;
      left: 0;
    }
    &.popup-fake-padding-2 {
      width: 25px;
      height: 100%;
      top: 0;
      right: -25px;
    }
    &.popup-fake-padding-3 {
      height: 25px;
      width: 100%;
      bottom: -25px;
      left: 0;
    }
    &.popup-fake-padding-4 {
      width: 25px;
      height: 100%;
      top: 0;
      left: -25px;
    }
  }
`

interface Props {
  popup?: boolean
  children?: React.ReactNode
}

export default function PopupInner({ children }: Props) {
  return (
    <StyledPopupContainer>
      <StyledPopupInner>
        <div className="popup-content">{children}</div>
        <object
          data={corner}
          type="image/svg+xml"
          className="popup-corner popup-corner-1"
          aria-label="corner"
        />
        <object
          data={corner}
          type="image/svg+xml"
          className="popup-corner popup-corner-2"
          aria-label="corner"
        />
        <object
          data={corner}
          type="image/svg+xml"
          className="popup-corner popup-corner-3"
          aria-label="corner"
        />
        <object
          data={corner}
          type="image/svg+xml"
          className="popup-corner popup-corner-4"
          aria-label="corner"
        />
        <span className="popup-fake-padding popup-fake-padding-1" />
        <span className="popup-fake-padding popup-fake-padding-2" />
        <span className="popup-fake-padding popup-fake-padding-3" />
        <span className="popup-fake-padding popup-fake-padding-4" />
      </StyledPopupInner>
    </StyledPopupContainer>
  )
}
