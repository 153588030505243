import React from "react"
import styled from "styled-components"
import { useGlobalStore } from "../utils/globalStore"
import { useLocaleCheck } from "../utils/useLocaleCheck"

const StyledTermsAndConditions = styled.p`
  font-size: 0.9rem;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
`

export default function TermsAndConditions() {
  const data = useGlobalStore((state) => state.data)
  const termsData = useLocaleCheck(data?.home)

  return (
    <StyledTermsAndConditions>
      {termsData?.terms_and_conditions_text}{" "}
      <a href={termsData?.terms_and_conditions_link_address}>
        {termsData?.terms_and_conditions_link_text}
      </a>
    </StyledTermsAndConditions>
  )
}
