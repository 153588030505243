import create from "zustand"

interface GlobalStore {
  audio: boolean
  setAudio: (a: any) => void
  data: any
  setData: (d: any) => void
  strengthData: any
  setStrengthData: (d: any) => void
  lang: string
  setLang: (l: string) => void
  locale: string
  setLocale: (l: string) => void
}

export const useGlobalStore = create<GlobalStore>()((set) => ({
  audio: false,
  setAudio: (a) => set((_state) => ({ audio: a })),
  data: null,
  setData: (d) => set((_state) => ({ data: d })),
  strengthData: null,
  setStrengthData: (d) => set((_state) => ({ strengthData: d })),
  lang: "en",
  setLang: (l) => set((_state) => ({ lang: l })),
  locale: "uk",
  setLocale: (l) => set((_state) => ({ locale: l })),
}))
