export enum CookiesKeys {
  RingToss = "ringtoss",
  StrengthTest = "strengthtest",
}

export enum PrizeKeys {
  Bronze = "BRONZE",
  Silver = "SILVER",
  Gold = "GOLD",
  Lost = "LOST",
}