import React from "react"
import styled from "styled-components"
import { gsap } from "gsap"

const StyledPractiseMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

interface Props {
  isPractise?: string
}

export default function PractiseMessage({ isPractise }: Props) {
  const ref = React.useRef<any | null>(null)

  React.useEffect(() => {
    gsap.to(ref.current, {
      delay: isPractise === "start" ? 2.5 : 0,
      autoAlpha: isPractise === "start" ? 0 : 1,
    })
  }, [isPractise])

  return (
    <StyledPractiseMessage ref={ref}>
      <h1>{isPractise === "start" ? "Practise Throw" : "Click to begin"}</h1>
    </StyledPractiseMessage>
  )
}
