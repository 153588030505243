import React from "react"
import styled from "styled-components"
import { Outlet, useLocation } from "react-router-dom"
import { gsap } from "gsap"
import tentBackgroundImg from "../assets/tent-background.jpg"
import curtainImg from "../assets/curtain.jpg"
import TopButtonFlex from "./TopButtonFlex"

interface StyledProps {
  curtainWidth?: boolean
}

const StyledOutletLayout = styled.div<StyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  overflow: hidden;
  .outlet__background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outlet__tent {
    position: relative;
    height: 100%;
    z-index: -1;
    img {
      height: 100%;
      min-width: 375px;
      object-fit: cover;
      ${(props) => props.curtainWidth && `width: 375px;`}
    }
    .outlet__tent__gradient {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.8) 33%,
        rgba(0, 0, 0, 0.8) 66%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }
  .outlet__curtain {
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    img {
      height: 100%;
      min-width: 250px;
      object-fit: cover;
    }
  }
  .outlet__curtain-left {
    justify-content: right;
    align-items: right;
    :after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  .outlet__curtain-right {
    :after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
`

export default function OutletLayout() {
  const location = useLocation()

  const tl = React.useRef<gsap.core.Timeline>(
    gsap.timeline({ defaults: { duration: 1 } })
  )
  const leftCurtain = React.useRef<HTMLImageElement | null>(null)
  const rightCurtain = React.useRef<HTMLImageElement | null>(null)

  function curtainAnimation() {
    const leftOffset = leftCurtain.current?.getBoundingClientRect().right || 250
    const rightOffset =
      rightCurtain.current?.getBoundingClientRect().left || 250
    const lPos = window.innerWidth / 2 - leftOffset
    const rPos = window.innerWidth / 2 - rightOffset

    tl.current
      .fromTo(leftCurtain.current, { x: lPos }, { x: 0, delay: 0.5 })
      .fromTo(rightCurtain.current, { x: rPos }, { x: 0 }, "<")
      .fromTo(
        ".outlet__tent__gradient",
        { opacity: 0 },
        { opacity: 1 },
        "-=0.7"
      )
      .fromTo(
        "main",
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.3 },
        "-=0.5"
      )
  }

  React.useEffect(() => {
    curtainAnimation()
  }, [location])

  return (
    <>
      <StyledOutletLayout
        curtainWidth={location.pathname === "/ring-toss/play"}
      >
        <div className="outlet__background">
          <div className="outlet__curtain outlet__curtain-left">
            <img ref={leftCurtain} alt="" src={curtainImg} />
          </div>
          <div className="outlet__tent">
            <img alt="" src={tentBackgroundImg} />
            <div className="outlet__tent__gradient" />
          </div>
          <div className="outlet__curtain outlet__curtain-right">
            <img ref={rightCurtain} alt="" src={curtainImg} />
          </div>
        </div>
        <main>
          <TopButtonFlex />
          <Outlet />
        </main>
      </StyledOutletLayout>
    </>
  )
}
