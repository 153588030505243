import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const StyledInstructions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
  img {
    width: 100px;
  }
  p {
    max-width: 220px;
  }
  a {
    width: 100%;
  }
`

interface Props {
  data?: any
}

export default function Instructions({ data }: Props) {
  return (
    <StyledInstructions>
      <h1>{data?.section[0].title}</h1>
      <img
        alt=""
        src={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[0].image.name}`}
      />
      <p>{data?.section[0].text}</p>
      <img
        alt=""
        src={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[1].image.name}`}
      />
      <p>{data?.section[1].text}</p>
      <img
        alt=""
        src={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[2].image.name}`}
      />
      <p>{data?.section[2].text}</p>
      <Link to="/ring-toss/play" className="button-home">
        {data?.section[2].button_text}
      </Link>
    </StyledInstructions>
  )
}
