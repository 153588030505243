import { useGlobalStore } from "./globalStore"

export const useLocaleCheck = (data: any) => {
  const lang = useGlobalStore((state) => state.lang)
  const locale = useGlobalStore((state) => state.locale)

  return data?.content[locale][lang]
}

export const useSettingsCheck = (data: any) => {
  const locale = useGlobalStore((state) => state.locale)

  return data?.content[locale]
}
