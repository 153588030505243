import React from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { eventsCenter } from "../utils/eventsEmmiter"
import { useGlobalStore } from "../utils/globalStore"
import musicOnSvg from "../assets/music-on.svg"
import musicOffSvg from "../assets/music-off.svg"

const StyledAudioButton = styled.nav`
  button {
    background: transparent;
    border: none;
    outline: none;
    * {
      pointer-events: none;
      filter: drop-shadow(3px 3px 3px black);
    }
  }
`

export default function AudioButton() {
  const audio = useGlobalStore((state) => state.audio)
  const setAudio = useGlobalStore((state) => state.setAudio)
  const location = useLocation()
  const isPlayRoute = location.pathname.includes("/play")

  React.useEffect(() => {
    if (localStorage.getItem("audio") === "off") {
      setAudio(false)
      const music = document.getElementById("audio") as HTMLAudioElement
      music?.pause()
    } else {
      setAudio(true)
      const music = document.getElementById("audio") as HTMLAudioElement
      music?.play()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleAudio() {
    if (audio) {
      setAudio(false)
      isPlayRoute && eventsCenter.emit("audio", false)
      localStorage.setItem("audio", "off")
      const music = document.getElementById("audio") as HTMLAudioElement
      music?.pause()
    } else {
      setAudio(true)
      isPlayRoute && eventsCenter.emit("audio", true)
      localStorage.setItem("audio", "on")
      const music = document.getElementById("audio") as HTMLAudioElement
      music?.play()
    }
  }

  return (
    <>
      <StyledAudioButton>
        <button onClick={handleAudio}>
          {!audio ? (
            <object data={musicOffSvg} aria-label="button" />
          ) : (
            <object data={musicOnSvg} aria-label="button" />
          )}
        </button>
      </StyledAudioButton>
      <audio src="/music.mp3" loop id="audio" />
    </>
  )
}
