import { css } from "styled-components"

const global = css`
  :root {
    --white: #fffad9;
    --black: #000000;
    --maroon: #5e2220;
    --yellow: #fffad9;
    --red: #cc2d05;
    --snow: #e3edf4;
  }

  body {
    width: 100%;
    min-height: calc(var(--vh) * 100);
    background: var(--maroon);
    font-size: 20px;
    font-family: "Cormorant Garamond", serif;
    text-align: center;
    position: relative;
    color: var(--yellow);
  }

  img {
    display: block;
    max-width: 100%;
    user-select: none;
    -webkit-user-drag: none;
  }

  a,
  button {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  .signmaster {
    font-family: "Signmaster";
    font-weight: 400;
    text-transform: uppercase;
  }

  canvas {
    cursor: pointer;
  }

  .button {
    display: inline-block;
    padding: 10px 30px;
    font-size: 1.3rem;
    font-family: "Signmaster";
    color: var(--yellow);
    border: 1px solid var(--red);
    outline: none;
    background: var(--red);
    transition: 0.3s;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 auto;
    border-radius: 30px;
    width: 100%;
    &:hover {
      background: transparent;
      color: var(--black);
    }
    &:focus {
      outline: none;
    }
  }

  .button-home {
    display: inline-block;
    padding: 10px 30px;
    font-size: 1.3rem;
    font-family: "Signmaster";
    color: var(--yellow);
    border: 1px solid var(--red);
    outline: none;
    background: var(--red);
    transition: 0.3s;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 auto;
    border-radius: 30px;
    word-break: keep-all;
    white-space: nowrap;
    &:hover {
      background: var(--black);
      color: var(--yellow);
      border: 1px solid var(--black);
    }
    &:focus {
      outline: none;
    }
  }

  .button-alt {
    display: inline-block;
    padding: 10px 30px;
    font-size: 1.3rem;
    font-family: "Signmaster";
    color: var(--yellow);
    border: 1px solid var(--black);
    outline: none;
    background: var(--black);
    transition: 0.3s;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 auto;
    border-radius: 30px;
    width: 100%;
    &:hover {
      background: transparent;
      color: var(--black);
    }
    &:focus {
      outline: none;
    }
  }

  .gold {
    background: linear-gradient(
      to bottom,
      #cfc09f 22%,
      #634f2c 24%,
      #cfc09f 26%,
      #cfc09f 27%,
      #ffecb3 40%,
      #3a2c0f 78%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    position: relative;
  }

  .shadow {
    filter: drop-shadow(0 0 6px var(--white));
  }
`

export default global
