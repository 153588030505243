import React from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"
import { CookiesKeys, PrizeKeys } from "../../../utils/keys"
import { getCookies } from "../../../utils/cookies"
import { useRingTossStore } from "../../../utils/ringTossStore"
import PrizeLayout from "./PrizeLayout"
import { usePageLoad } from "../../../utils/usePageLoad"
import Loading from "../../Loading"
import { useLocaleCheck, useSettingsCheck } from "../../../utils/useLocaleCheck"
import { useGlobalStore } from "../../../utils/globalStore"
import { endApiUrl } from "../../../utils/apiUrls"

export default function RingTossPrize() {
  const [loading, setLoading] = React.useState<boolean>(true)

  const data = useGlobalStore((state) => state.data)
  const id = useRingTossStore((state) => state.id)
  const prize = useRingTossStore((state) => state.prize)
  const setPrize = useRingTossStore((state) => state.setPrize)
  const prevPrize = useRingTossStore((state) => state.prevPrize)
  const setPrevPrize = useRingTossStore((state) => state.setPrevPrize)
  const setBackupPrize = useRingTossStore((state) => state.setBackupPrize)
  const score = useRingTossStore((state) => state.score)

  const prizeData = useLocaleCheck(data?.prize)
  const gameOverData = useLocaleCheck(data?.["game-over"])
  const settingData = useSettingsCheck(data?.settings)

  const hasAttemptsLeft =
    getCookies(CookiesKeys.RingToss) > 0 ||
    isNaN(getCookies(CookiesKeys.RingToss))

  const wonPrevPrize =
    !hasAttemptsLeft && prize === PrizeKeys.Lost && prevPrize !== PrizeKeys.Lost

  const bronzeThreshold = settingData?.bronze.threshold || 20
  const bronzeStock = settingData?.bronze.stock > 0
  const bronzeAward = bronzeStock && score >= bronzeThreshold

  const silverThreshold = settingData?.silver.threshold || 60
  const silverStock = settingData?.silver.stock > 0
  const silverAward = silverStock && score >= silverThreshold

  const goldThreshold = settingData?.gold.threshold || 100
  const goldStock = settingData?.gold.stock > 0
  const goldAward = goldStock && score >= goldThreshold

  const lostAward = score >= 0 && score < bronzeThreshold

  function prevScore() {
    if (wonPrevPrize) {
      if (prevPrize === PrizeKeys.Bronze) {
        return parseInt(bronzeThreshold, 10) + 1
      } else if (prevPrize === PrizeKeys.Silver) {
        return parseInt(silverThreshold, 10) + 1
      } else {
        return parseInt(goldThreshold, 10) + 1
      }
    }
  }

  async function handleEndApi() {
    try {
      const res = await axios.post(endApiUrl, {
        id,
        point: score,
      })
      setPrize(res?.data?.data.prize.toUpperCase())
      if (wonPrevPrize) {
        const prevRes = await axios.post(endApiUrl, {
          id,
          point: prevScore(),
        })
        setPrevPrize(prevRes?.data?.data.prize.toUpperCase())
        setBackupPrize(prevRes?.data?.data.prize.toUpperCase())
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function prizeSetter() {
    if (!prize) {
      setPrevPrize(PrizeKeys.Lost)
    }

    switch (true) {
      case goldAward:
        // setPrize(PrizeKeys.Gold)
        setPrevPrize(PrizeKeys.Gold)
        break
      case silverAward:
        // setPrize(PrizeKeys.Silver)
        break
      case bronzeAward:
        // setPrize(PrizeKeys.Bronze)
        break
      case lostAward:
        // setPrize(PrizeKeys.Lost)
        break
      default:
        return
    }

    switch (true) {
      case bronzeAward &&
        prize !== PrizeKeys.Silver &&
        prize !== PrizeKeys.Gold:
        setPrevPrize(PrizeKeys.Bronze)
        break
      case silverAward && prize !== PrizeKeys.Gold:
        setPrevPrize(PrizeKeys.Silver)
        break
      default:
        return
    }
  }

  React.useEffect(() => {
    handleEndApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    prizeSetter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, prize])

  const wonKey =
    prize && prize !== PrizeKeys.Lost ? prize?.toLocaleLowerCase() : "bronze"
  const prevWonKey =
    prevPrize && prevPrize !== PrizeKeys.Lost
      ? prevPrize?.toLocaleLowerCase()
      : "bronze"

  const wonCopy = prizeData?.[wonKey].copy
  const wonButton1 = prizeData?.[wonKey].button_1
  const wonButton2 = prizeData?.[wonKey].button_2
  const wonImg = prizeData?.[wonKey].image.name
  const prevWonImg = prizeData?.[prevWonKey].image.name

  return (
    <>
      {!id && <Navigate to="/ring-toss" />}
      {prize !== PrizeKeys.Lost ? (
        <PrizeLayout
          attemptsLeft={hasAttemptsLeft}
          copy={wonCopy}
          image={`${process.env.REACT_APP_IMAGES_URL}/${wonImg}`}
          button1={wonButton1}
          button2={
            hasAttemptsLeft ? wonButton2 : gameOverData?.section[0].button_2
          }
          buttonLink={gameOverData?.section[0].button_link}
          lost={prize === PrizeKeys.Lost}
          score={score}
        />
      ) : wonPrevPrize ? (
        <PrizeLayout
          attemptsLeft={hasAttemptsLeft}
          copy={gameOverData?.section[1].copy}
          image={`${process.env.REACT_APP_IMAGES_URL}/${prevWonImg}`}
          button1={gameOverData?.section[1].button_1}
          button2={
            hasAttemptsLeft ? wonButton2 : gameOverData?.section[0].button_2
          }
          buttonLink={gameOverData?.section[1].button_link}
          lost={prevPrize === PrizeKeys.Lost}
          score={score}
        />
      ) : (
        <PrizeLayout
          attemptsLeft={hasAttemptsLeft}
          copy={gameOverData?.section[0].copy}
          button1={gameOverData?.section[0].button_1}
          button2={
            hasAttemptsLeft ? wonButton2 : gameOverData?.section[0].button_2
          }
          buttonLink={gameOverData?.section[0].button_link}
          lost={prize === PrizeKeys.Lost}
          score={score}
        />
      )}
      {loading && <Loading />}
      {usePageLoad() && <Loading />}
    </>
  )
}
