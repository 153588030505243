import Cookies from "js-cookie"

let tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(0)
tomorrow.setMinutes(0)
tomorrow.setMilliseconds(0)

export function setCookiesForTomorrow(key: string, tries?: number | string) {
  const cookie = Cookies.get(key)

  if (cookie) {
    Cookies.set(key, `${parseInt(cookie, 10) - 1}`, { expires: tomorrow })
  } else if (!cookie) {
    Cookies.set(key, `${tries || 5}`, { expires: tomorrow })
  }
}

export function setCookies(key: string, tries?: number | string) {
  Cookies.set(key, `${tries || 5}`, { expires: tomorrow })
}

export function getCookies(key: string) {
  return parseInt(`${Cookies.get(key)}`, 10)
}

export function resetCookiesCheat(key: string) {
  let input = ""
  const keycode =
    "ArrowUpArrowDownArrowLeftArrowRightArrowUpArrowDownArrowLeftArrowRight"

  document.addEventListener("keydown", (e) => {
    input += e.code
    if (input === keycode) {
      return setCookies(key)
    }
    if (!keycode.indexOf(input)) return
    input = e.code
  })
}
