import React from "react"
import { useGlobalStore } from "../../../utils/globalStore"
import { PrizeKeys } from "../../../utils/keys"
import { useRingTossStore } from "../../../utils/ringTossStore"
import { useLocaleCheck, useSettingsCheck } from "../../../utils/useLocaleCheck"
import Popup from "../../Popup"
import Portal from "../../Portal"

enum AjaxPrizes {
  Gold = "PLAY1",
  Silver = "PLAY2",
  Bronze = "PLAY3",
}

interface Props {
  children?: React.ReactNode
}

export default function AjaxButton({ children }: Props) {
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false)
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const data = useGlobalStore((state) => state.data)
  const prize = useRingTossStore((state) => state.prize)
  const backupPrize = useRingTossStore((state) => state.backupPrize)
  const score = useRingTossStore((state) => state.score)
  const settingsData = useSettingsCheck(data?.settings)
  const redirectData = useLocaleCheck(data?.redirect)

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setFormSubmitted(true)

    // window.dataLayer.push({
    //   event: "addToCart",
    //   eventCategory: "ecommerce",
    //   eventAction: "add to cart",
    //   eventLabel: handlePrizeSelection(),
    //   ecommerce: {
    //     currencyCode: locale === "us" ? "usd" : "gbp",
    //   },
    // })

    console.log(`prize: ${handlePrizeSelection()} | score: ${score}`)

    setTimeout(() => {
      formRef.current?.submit()
    }, 2000)
  }

  function handlePrizeSelection() {
    if (backupPrize) {
      if (backupPrize === PrizeKeys.Gold) {
        return AjaxPrizes.Gold
      } else if (backupPrize === PrizeKeys.Silver) {
        return AjaxPrizes.Silver
      } else if (backupPrize === PrizeKeys.Bronze) {
        return AjaxPrizes.Bronze
      }
    }

    if (prize === PrizeKeys.Gold) {
      return AjaxPrizes.Gold
    } else if (prize === PrizeKeys.Silver) {
      return AjaxPrizes.Silver
    } else if (prize === PrizeKeys.Bronze) {
      return AjaxPrizes.Bronze
    }
  }

  return (
    <>
      <form
        ref={formRef}
        action={settingsData?.add_to_basket_link}
        method="post"
        onSubmit={(e) => handleFormSubmit(e)}
        style={{ width: "100%" }}
      >
        <input type="hidden" name="userScore" value={score} />
        <input type="hidden" name="userAttempts" value={1} />
        <input type="hidden" name="userPrize" value={handlePrizeSelection()} />
        <button type="submit" className="button">
          {children}
        </button>
      </form>
      {formSubmitted && (
        <Portal where="redirect">
          <Popup>
            <h2>{redirectData?.title}</h2>
            <p>{redirectData?.copy_1}</p>
            <p style={{ fontSize: "0.9rem" }}>{redirectData?.copy_2}</p>
          </Popup>
        </Portal>
      )}
    </>
  )
}
