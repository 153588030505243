import React from "react"
import styled from "styled-components"
import PopupInner from "./PopupInner"

export const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  background: rgba(0, 0, 0, 0.6);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

interface Props {
  children?: React.ReactNode
}

export default function Popup({ children }: Props) {
  return (
    <>
      <StyledPopup>
        <PopupInner>{children}</PopupInner>
      </StyledPopup>
    </>
  )
}
