import React from "react"
import styled from "styled-components"
import HomeLayout from "./HomeLayout"
import ImageLink from "./ImageLink"
import { usePageLoad } from "../../utils/usePageLoad"
import Loading from "../Loading"
// import ringTossImg from "../../assets/ringtoss.png"
// import fortuneImg from "../../assets/fortune.png"
// import giftFinderImg from "../../assets/gift-finder.png"
// import giftGuideImg from "../../assets/gift-guide.png"
// import cinemaImg from "../../assets/cinema.png"
// import strengthImg from "../../assets/strength-coming-soon.png"

const StyledHomeMap = styled.div`
  margin: 40px auto -80px;
  > *:not(:first-child) {
    margin-top: -80px;
  }
  > *:nth-child(even) {
    transform: translate(-120px, 0);
  }
  > *:nth-child(odd) {
    transform: translate(120px, 0);
  }
  @media (max-width: 500px) {
    margin: 40px auto 0;
    > * {
      transform: translate(0, 0) !important;
      margin-top: 0 !important;
      margin-bottom: 30px;
    }
  }
`

const StyledHomeGrid = styled.div`
  max-width: 1000px;
  padding: 20px;
  margin: auto;
  .home__grid__inner {
    max-width: 90vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: auto;
    * > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

interface Props {
  data?: any
}

const fakeLink = {
  image: "https://i3.ytimg.com/vi/E0YQ2F_ejnY/maxresdefault.jpg",
  copy: "Welcome to the world of wonder see what awaits you below, there are games to play and prizes to be won",
  linkAddress: "https://www.youtube.com/watch?v=E0YQ2F_ejnY",
  linkText: "Play",
}

export default function Home({ data }: Props) {
  return (
    <>
      <HomeLayout
        map={
          <>
            <p>{data?.section[0].text}</p>
            <StyledHomeMap>
              <ImageLink
                to={data?.section[1].button_link}
                copy={data?.section[1].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[1].image.name}`}
                bottom="90"
                width="220"
              />
              <ImageLink
                to={data?.section[2].button_link}
                copy={data?.section[2].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[2].image.name}`}
              />
              <ImageLink
                to={data?.section[3].button_link}
                copy={data?.section[3].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[3].image.name}`}
                width="200"
                bottom="0"
              />
              <ImageLink
                to={data?.section[4].button_link}
                copy={data?.section[4].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[4].image.name}`}
                width="200"
              />
              <ImageLink
                to={data?.section[5].button_link}
                copy={data?.section[5].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[5].image.name}`}
                width="200"
                bottom="20"
              />
              <ImageLink
                to={data?.section[6].button_link}
                copy={data?.section[6].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[6].image.name}`}
                width="200"
              />
              <ImageLink
                to={data?.section[7].button_link}
                copy={data?.section[7].button_text}
                image={`${process.env.REACT_APP_IMAGES_URL}/${data?.section[7].image.name}`}
                width="250"
              />
            </StyledHomeMap>
          </>
        }
        grid={
          <>
            <StyledHomeGrid>
              <div className="home__grid__inner">
                {Array.from({ length: 4 }).map((_, i) => (
                  <div key={i} className="home__grid__thumbnail">
                    <img alt="" src={fakeLink.image} />
                    <p>{fakeLink.copy}</p>
                    <a href={fakeLink.linkAddress} className="button">
                      {fakeLink.linkText}
                    </a>
                  </div>
                ))}
              </div>
            </StyledHomeGrid>
          </>
        }
      />
      {usePageLoad() && <Loading />}
    </>
  )
}
