import create from "zustand"

interface RingTossState {
  data: any
  setData: (d: any) => void
  id?: string | number
  setId: (id: string | number) => void
  prize: string | null
  setPrize: (p: string | null) => void
  prevPrize: string | null
  setPrevPrize: (p: string | null) => void
  backupPrize?: string | null
  setBackupPrize: (bp: string | null) => void
  score: number
  setScore: (p: number) => void
  tries: number
  setTries: (t: number) => void
}

export const useRingTossStore = create<RingTossState>()((set) => ({
  data: null,
  setData: (d) => set((_state) => ({ data: d })),
  setId: (id) => set((_state) => ({ id: id })),
  prize: "",
  setPrize: (p) => set((_state) => ({ prize: p })),
  prevPrize: "",
  setPrevPrize: (p) => set((_state) => ({ prevPrize: p })),
  setBackupPrize: (bp) => set((_state) => ({ backupPrize: bp })),
  score: 0,
  setScore: (p) => set((_state) => ({ score: p })),
  tries: 5,
  setTries: (t) => set((_state) => ({ tries: t })),
}))
